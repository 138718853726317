import React, { useEffect, useState } from 'react';
import { getAds } from '@/lib/api';
import Link from 'next/link';

const getFileType = (url) => {
    const extension = url.split('.').pop().toLowerCase();  // Get the file extension and convert it to lowercase

    if (['jpg', 'jpeg', 'png', 'gif'].includes(extension)) {
        return 'image';
    } else if (['mp4', 'webm', 'ogg'].includes(extension)) {
        return 'video';
    }

    return 'unknown';
}

const fetchAds = async () => {
    let today = new Date();
    today = today.toISOString();
    return await getAds(today);
}

const AdsContainer = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [ads, setAds] = useState([]);

    useEffect(() => {
        fetchAds().then(response => {
            setAds(response);
            setIsLoading(false);
        });
    }, []);

    return (
        <div className={`${ads.length > 0 ? 'ads-container' : ''}`}>
            {!isLoading && ads.length > 0 && (
                <Link href={ads[0].url} target="_blank" aria-label="Visit to get more information">
                    {
                        getFileType(ads[0].advert_image) === 'video' ? (
                            <video className="video1" muted autoPlay loop>
                                <source src={ads[0].advert_image} type={`video/${ads[0].advert_image.split('.').pop()}`} />
                            </video>
                        ) : (
                            <img className="video1" src={ads[0].advert_image} alt="Ad"/>
                        )
                    }
                </Link>
            )}
        </div>
    );
}

export default AdsContainer;

