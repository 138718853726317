import React, {useContext, useEffect, useState} from 'react';
import Link from 'next/link';
import Image from 'next/image';
import {delay, getPublicationData} from '@/lib/api';
import {AppContext} from '@/context/AppContext';
import PublicationSkeletonCard from '@/components/skeletons/PublicationSkeletonCard';
import {usePublication} from '@/context/PublicationContext';
import SkeletonCard from '@/components/skeletons/SkeletonCard';


function PublicationsBlock() {
    const {
        customImageLoader
    } = useContext(AppContext);

    const {
        publicationsLoading,
        setPublicationsLoading
    } = usePublication();

    const [publications, setPublications] = useState([]);
    const [numberOfPublications, setNumberOfPublications] = useState(0);
    const [showMoreSkeleton, setShowMoreSkeleton] = useState(false);

    useEffect(() => {
        fetchPublications().then();
    }, []);

    const fetchPublications = async ( getAll = false) => {

        const response = await getPublicationData(getAll);
        setPublications(response.publications);
        setNumberOfPublications(response.pagination.totalPublications);

        if (response.publications.length !== 0) {
            await delay(300);
        }

        setPublicationsLoading(false);
    }

    const showAllPublications = async (e) => {
        await fetchPublications(true);
        setShowMoreSkeleton(false);
    };

    return (
        <div className="publication-block">
            <h4 className="print_head">Archives</h4>
            <div className="publication-cards mt-4">
                <div className="row row-cols-2 row-cols-md-4 row-cols-lg-4">
                    {publicationsLoading ? (
                        <SkeletonCard
                            length={6}
                            Component={PublicationSkeletonCard}
                        />
                    ) : (
                        publications.map(({id, slug, logo, name}) => (
                            <div
                                className="col publication-cards-hover"
                                key={id}
                                onMouseOver={(e) => e.currentTarget.classList.add('publication-card-hover')}
                                onMouseLeave={(e) => e.currentTarget.classList.remove('publication-card-hover')}
                            >
                                <Link href={slug ? {pathname: slug} : {pathname: '/publication' + slug}}>
                                    <span>
                                        <div className="card border-0 publication-card">
                                            <div className='card-image'>
                                                <Image
                                                    loader={() => {
                                                        return customImageLoader(logo, 230, 100, 75)
                                                    }}
                                                    src={logo}
                                                    alt="publication-card"
                                                    height={100}
                                                    width={230}
                                                />
                                            </div>
                                            <div className="card-body px-0">
                                                <h5 className="card-title text-purple">{name}</h5>
                                            </div>
                                        </div>
                                    </span>
                                </Link>
                            </div>
                        ))
                    )}

                    {showMoreSkeleton && (
                        <SkeletonCard
                            length={8}
                            Component={PublicationSkeletonCard}
                        />
                    )}
                </div>
            </div>
            {publications.length <= 0 && <div className="no_publicationall_text mt-3">No publication found</div>}
            {numberOfPublications > publications.length && (
                <div className="show-more text-center mt-1">
                    <p
                        id="showPublications"
                        role="button"
                        className="text-purple"
                        onClick={async () => {
                            setShowMoreSkeleton(true);
                            await showAllPublications()
                        }}
                    >
                        Show all ({numberOfPublications})
                    </p>
                </div>
            )}
        </div>
    );
}

export default PublicationsBlock;
