import React, {useContext, useEffect, useState} from 'react';
import {AppContext} from '@/context/AppContext';

const SortMenu = ({printouts, setPrintouts, floatPosition = 'right'}) => {
    const {isLanguageMenuOpened} = useContext(AppContext);

    const [dateOrder, setDateOrder] = useState('asc');
    const [sortArrow, setSortArrow] = useState('↓');

    useEffect(() => {
        sortByDate();
    }, [isLanguageMenuOpened]);

    const sortPrintouts = (key, order = 'asc') => {
        const sortedPrintouts = [...printouts].sort((a, b) => {
            const aValue = key.split('.').reduce((o, i) => o[i], a);
            const bValue = key.split('.').reduce((o, i) => o[i], b);
            if (aValue < bValue) return order === 'asc' ? -1 : 1;
            if (aValue > bValue) return order === 'asc' ? 1 : -1;
            return 0;
        });
        setPrintouts(sortedPrintouts);
    };

    const handleActiveFilter = (e) => {
        const activeFilter = document.querySelector('.activefilter');
        if (activeFilter) {
            activeFilter.classList.remove('activefilter');
        }
        if (e) {
            e.target.classList.add('activefilter');
        }
    };

    const sortByDate = (e) => {
        if (dateOrder === 'dsc') {
            sortPrintouts('published_at', 'asc');
            setDateOrder('asc');
            setSortArrow("↑");
        } else {
            sortPrintouts('published_at', 'dsc');
            setDateOrder('dsc');
            setSortArrow("↓");
        }
        handleActiveFilter(e);
    };

    const sortByNameAsc = (e) => {
        sortPrintouts('publication.name', 'asc');
        handleActiveFilter(e);
    };

    const sortByNameDesc = (e) => {
        sortPrintouts('publication.name', 'dsc');
        handleActiveFilter(e);
    };

    return (
        <div className="language-filter sort-filter" style={{float: floatPosition}}>
            <label>
                Sort by:{' '}
                <span role="button" className="activefilter" onClick={sortByDate}>
                    {sortArrow} Date
                </span>
                <span role="button" onClick={sortByNameAsc}>
                    A-Z
                </span>
                <span role="button" onClick={sortByNameDesc}>
                    Z-A
                </span>
            </label>
        </div>
    );
};

export default SortMenu;
