import React from 'react';

const SkeletonCard = ({ length, Component }) => {
    return (
        <>
            {Array.from({ length }, (_, index) => (
                <Component key={index} />
            ))}
        </>
    );
};

export default SkeletonCard;
